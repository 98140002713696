<template>
    <div>

        <div
                style="display: flex; align-items: center; justify-content: space-between"
        >
            <label class="label" style="flex: 3">Your hotel users rating</label>
        </div>
        <div style="margin: 15px 0">

            <v-data-table
                    class="table"
                    :headers="headers"
                    :items="clientsBalances"
                    hide-default-footer
            >
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                    <tr
                            v-for="item in clientsBalances"
                            :key="item.email"
                    >
                        <td style="text-align: center;">
                            {{ item.name }} {{item.last_name}}
                        </td>
                        <td style="text-align: center;">{{item.balance}}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import {mapActions,mapState} from "vuex"
  export default {
    name: "ClientRating",
    data(){
      return{
        headers: [
          { text: "User", align: "center", sortable: true },
          { text: "Rating", align: "center", sortable: true },
        ],
      }
    },
    computed:{
      ...mapState('quests',['clientsBalances']),
    },
    methods:{
      ...mapActions('quests',['getClientsBalances'])
    },
    async mounted(){
      await this.getClientsBalances(this.$route.params.id);
    }
  }
</script>

<style scoped>

</style>
